













import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { BrandsType } from '~/constants/brands';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';

export const ALLOWED_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const;
export type BrandImageSize = ValueOf<typeof ALLOWED_SIZES>;

export default defineComponent({
  name: 'BrandImage',
  components: {
    ImgView,
  },
  props: {
    brand: {
      type: Object as PropType<BrandsType>,
      required: true,
    },
    size: {
      type: String as PropType<BrandImageSize>,
      default: ALLOWED_SIZES.SMALL,
      validator: (value: unknown): boolean => {
        return Object.values(ALLOWED_SIZES).includes(value as BrandImageSize);
      },
    },
  },
  setup(props) {
    const classesForSize = {
      [ALLOWED_SIZES.SMALL]: 'w-32 h-16',
      [ALLOWED_SIZES.MEDIUM]: 'w-72 h-32',
    };
    const imageClasses = computed(() => {
      return `${classesForSize[props.size]} object-contain`;
    });
    const componentSizeClasses = computed(() => {
      return classesForSize[props.size];
    });
    return {
      componentSizeClasses,
      imageClasses,
    };
  },
});
