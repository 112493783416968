




import { computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { createWidgetMixin } from 'vue-instantsearch';
import type { SortingConfiguration } from 'vue-instantsearch';
import { connectSortBy } from 'instantsearch.js/es/connectors';

export default defineComponent({
  name: 'VirtualSortBy',
  mixins: [
    createWidgetMixin({ connector: connectSortBy }),
  ],
  props: {
    items: {
      type: Array as PropType<SortingConfiguration[]>,
      required: true,
    },
  },
  setup(props) {
    const widgetParams = computed(() => {
      return { items: props.items };
    });

    return {
      widgetParams,
    };
  },
});
