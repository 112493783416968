import { render, staticRenderFns } from "./ProductTopReview.vue?vue&type=template&id=01ba5593&"
import script from "./ProductTopReview.vue?vue&type=script&lang=ts&"
export * from "./ProductTopReview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports