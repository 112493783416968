














import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import VueScrollTo from 'vue-scrollto';
import { PDP_NAV_ANCHORS } from '~/constants/pdpNavAnchors';
import { useAnchorNavigation } from '~/composables';
import type { ProductAnchorNavItem } from '~/types/product/AnchorNav';

export default defineComponent({
  name: 'ProductAnchorNavItem',
  props: {
    item: {
      type: Object as PropType<ProductAnchorNavItem>,
      required: true,
    },
  },
  setup (props) {
    const { activeSection, stickyAnchorNavHeight } = useAnchorNavigation();
    // needed addidional px to enter section and make it active in intersector observer
    const additionalPxToEnterSection = 1;

    const scrollToAnchor = () => {
      VueScrollTo.scrollTo(`#${props.item.sectionId}`, {
        offset: -stickyAnchorNavHeight.value + additionalPxToEnterSection,
      });
    };
    const isItemActive = computed(() => activeSection.value === props.item.sectionId);

    return {
      PDP_NAV_ANCHORS,
      scrollToAnchor,
      isItemActive,
    };
  },
});
