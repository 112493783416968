
































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';

export default defineComponent({
  name: 'ModalHeading',
  props: {
    title: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 2,
    },
    hasIconClose: {
      type: Boolean,
      default: true,
    },
    titleInCapitals: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: {title: string, titleInCapitals: boolean}) {
    const headingTitle = computed(() => props.titleInCapitals ? props.title.toUpperCase() : props.title);
    return {
      headingTitle,
    };
  },
});
