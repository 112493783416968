





















import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '@vueuse/core';
import { useIntersectionObserverEvent } from '~/composables/modules/useIntersectionObserverEvent';
import { useIntegrations } from '~/composables';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';
import ProductSlider from '~/components/organisms/Product/ProductSlider/ProductSlider.vue';
import PdpComplementaryProductCard from
  '~/components/molecules/Product/ComplementaryProducts/PdpComplementaryProductCard/PdpComplementaryProductCard.vue';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { PRODUCT_CARD_SIZE } from '~/constants/productCardSize';

export default defineComponent({
  name: 'ComplementaryProducts',
  components: {
    ProductAnchorTitle,
    ProductSlider,
    PdpComplementaryProductCard,
  },
  props: {
    complementaryProducts: {
      type: Array as PropType<ProductVariantExtended[]>,
      required: true,
    },
  },
  setup(props) {
    const { $tagManager } = useIntegrations();
    const complementaryProductsNode = ref<HTMLElement>(null);

    const { onIntersectOnce: onIntersectComplementaryOnce } = useIntersectionObserverEvent({
      onIntersectCallback: () => {
        if (props.complementaryProducts.length) {
          const skus = props.complementaryProducts.map((product: ProductVariantExtended) => product.sku);
          $tagManager.events.triggerViewComplementaryTags(skus);
        }
      },
    });

    useIntersectionObserver(complementaryProductsNode, onIntersectComplementaryOnce);

    const onClick = (product: ProductVariantExtended) => {
      $tagManager.events.triggerClickComplementaryTags(product.sku);
    };

    return {
      PRODUCT_CARD_SIZE,
      complementaryProductsNode,
      onClick,
    };
  },
});
