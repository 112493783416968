
















import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';
import CheckboxPickerOption from '~/components/atoms/Search/CheckboxPickerOption/CheckboxPickerOption.vue';
import { useStoreVsfExtended } from '~/composables';
import { CAMPAIGN_INDICATOR } from '~/constants/commerceTools';

export default defineComponent({
  name: 'CheckboxPicker',
  components: {
    CheckboxPickerOption,
  },
  props: {
    items: {
      type: Array as PropType<RefinementListItem[]>,
      required: true,
    },
    refine: {
      type: Function,
      required: true,
    },
    facet: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const { isActiveCampaignLabel } = useStoreVsfExtended();

    const filteredItems = computed(() =>
      props.items.filter(item => {
        if (item.value.includes(CAMPAIGN_INDICATOR)) {
          return isActiveCampaignLabel(item.value);
        }
        return item;
      }),
    );
    return {
      filteredItems,
    };
  },
});
