








import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductAnchorTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    headerClass: {
      type: String,
      default: 'text-EXPONDO-black',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
});
