





























































import { ref, defineComponent, toRefs, computed } from '@nuxtjs/composition-api';
import type { PropType, ComputedRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { productGetters } from '~/utils/ct';
import {
  useAddToCart,
  useIsMounted,
  useProductPrice,
  useAddToCartQuantity,
  useRouteExtended,
} from '~/composables';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';
import QuantitySelector from '~/components/atoms/Product/QuantitySelector.vue';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { ROUTES } from '~/constants/routes';

export default defineComponent({
  name: 'AddToCart',
  components: {
    Button,
    QuantitySelector,

  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true,
    },
    productAdjustedForCart: {
      type: Object as PropType<ProductVariant>,
      default: () => {},
    },
    hasQuantitySelector: {
      type: Boolean,
      default: true,
    },
    predefinedQuantity: {
      type: Number,
      default: null,
    },
    isFloatingAddToCart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root: { $route } }) {
    const { product } = toRefs(props);

    const { slug } = $route.params;
    const initialQuantityToAdd = ref(1);

    const { getAdjustedSlug } = useRouteExtended();
    const { app: { router } } = useVSFContext();

    const quantityToAdd: ComputedRef<number> = computed(
      () => props.predefinedQuantity || initialQuantityToAdd.value,
    );

    const productSku = productGetters.getSku(props.product);
    const productName = productGetters.getName(props.product);
    const productImage = productGetters.getCoverImage(props.product);
    const { getPriceInformation } = useProductPrice();
    const { productPrice } = getPriceInformation(product);
    const {
      availableQuantity,
      isQuantitySelectionPossible,
      addToCartButtonText,
    } = useAddToCart(slug, productSku);

    const { addQuantity } = useAddToCartQuantity({
      sku: productSku,
      slug,
      productPrice: productPrice.value,
      productName,
      productImage,
      isMainProduct: true,
      addToCartText: addToCartButtonText.value,
    });

    const onAddQuantity = () => {
      addQuantity(quantityToAdd.value);
      resetQuantity();
    };

    const onBuyNow = async () => {
      await addQuantity(quantityToAdd.value, { isWithExtraGuarantee: false, shouldOpenCart: true });
      resetQuantity();
      router.push(getAdjustedSlug(ROUTES.CHECKOUT_ADDRESSES));
    };

    const canShowBuyNow = computed(() => {
      return !props.isFloatingAddToCart && isQuantitySelectionPossible.value;
    });

    const { isMounted } = useIsMounted();

    const resetQuantity = () => {
      initialQuantityToAdd.value = 1;
    };

    const quantityChanged = (value: number) => {
      initialQuantityToAdd.value = value;
    };

    const schemaAvailabilityLink = computed(() => {
      if (isTestProduct(productSku)) {
        return SCHEMA_ITEM_TYPE.OFFER_PRE_ORDER;
      } else if (isQuantitySelectionPossible.value) {
        return SCHEMA_ITEM_TYPE.OFFER_IN_STOCK;
      } else {
        return SCHEMA_ITEM_TYPE.OFFER_OUT_OF_STOCK;
      }
    });

    return {
      isMounted,
      initialQuantityToAdd,
      availableQuantity,
      onAddQuantity,
      isQuantitySelectionPossible,
      addToCartButtonText,
      SCHEMA_ITEM_PROP,
      SCHEMA_ITEM_TYPE,
      quantityChanged,
      schemaAvailabilityLink,
      productSku,
      EVENT_SOURCE,
      onBuyNow,
      canShowBuyNow,
    };
  },
});

