



































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';
import ProductCardBadges from '~/components/molecules/Product/ProductCardBadges.vue';
import ProductCardPricing from '~/components/molecules/Product/ProductCardPricing/ProductCardPricing.vue';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import buildProductRating from '~/helpers/product/buildProductRating';
import { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { MEDIUM_CARD } from '~/constants/productCard';
import { ENERGY_RATING_SIZE } from '~/constants/energyRating';
import { PRODUCT_PRICING_SIZE } from '~/constants/productCardPricing';
import { useIntegrations } from '~/composables';
import type { ProductCategories } from '~/types/product/ProductCategories';
import { getFinalPrice } from '~/helpers/product/getFinalPrice';

export default defineComponent({
  name: 'MediumProductCard',
  components: {
    Link,
    TwicpicsImage,
    ProductCardBadges,
    ProductCardPricing,
    ProductAverageReview: () => import(/* webpackChunkName: "ProductAverageReview" */
      '~/components/atoms/Product/ProductAverageReview.vue'),
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    discountedPrice: {
      type: Number,
      default: null,
    },
    sku: {
      type: String,
      required: true,
    },
    reviewsCount: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: '',
    },
    tags: {
      type: Array as PropType<ProductTag[]>,
      default: () => {
        return [];
      },
    },
    brand: {
      type: String,
      default: '',
    },
    energyRating: {
      type: Object as PropType<EnergyRatingDetails>,
      default: () => ({}),
    },
    currency: {
      type: String,
      required: true,
    },
    categories: {
      type: [Object, String] as PropType<ProductCategories>,
      required: true,
    },
    productBoxTypeTag: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $tagManager } = useIntegrations();

    const finalPrice = computed(() => getFinalPrice(props.discountedPrice, props.price));
    const showCardBadge = computed(() => props.tags?.length);
    const ratingToShow = computed(() => {
      return buildProductRating({
        averageRating: props.rating,
        count: props.reviewsCount,
      });
    });

    const onClick = () => {
      emit('product-clicked');
      $tagManager.events.triggerClickProductBoxTags({
        boxType: props.productBoxTypeTag,
        product: {
          id: props.sku,
          name: props.title,
          currency: props.currency,
          discountedPrice: finalPrice.value,
          brand: props.brand,
          price: props.price,
          categories: props.categories,
        },
      });
    };

    return {
      LinkType,
      ratingToShow,
      showCardBadge,
      MEDIUM_CARD,
      ENERGY_RATING_SIZE,
      PRODUCT_PRICING_SIZE,
      onClick,
    };
  },
});
