import {
  EXCLUDED_FACETS,
  MAX_FACETS,
} from '~/constants/algolia';
import type { HitResultFacets } from '~/types/search/HitResultFacets';

export default (facets: HitResultFacets): string[] => {
  const sortableFacets = [];
  Object.entries(facets).forEach(([key, facetValue]) => {
    if (!EXCLUDED_FACETS.includes(key)) {
      sortableFacets.push([key, Object.values(facetValue)?.reduce((prev, curr) => prev + curr)]);
    }
  });

  // Returning only first 30 facets with largest amount of results
  // TODO INSP-342-define final logic of showing dynamic facets - how much of them to show, which order etc...
  return sortableFacets
    .sort((a, b) => b[1] - a[1])
    .splice(0, MAX_FACETS)
    .map((entry) => entry[0]);
};
