



















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { VolumeDiscount } from '~/types/volumeDiscounts';

export default defineComponent({
  name: 'VolumeDiscountRow',
  props: {
    volumeDiscount: {
      type: Object as PropType<VolumeDiscount>,
      required: true,
    },
    finalPrice: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const getDiscountedByVolume = (price: number, volumeDiscount: VolumeDiscount): number => {
      if (volumeDiscount.volume === 1) {
        return price;
      }

      return price - (price / 100 * volumeDiscount.discountPercentage);
    };

    return {
      getDiscountedByVolume,
    };
  },
});

