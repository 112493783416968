






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VatInfo',
  props: {
    isNetInvoiceEnabled: {
      type: Boolean,
      default: false,
    },
    vatPercentage: {
      type: Number,
      default: undefined,
    },
  },
});
