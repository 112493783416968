import { PageViewPageCategory } from '~/types/integrations/tagManager/TagManagerIntegration';

export const getPageCategory = (
  isNewCategoryTree: boolean,
  statusCode?: number,
): PageViewPageCategory => {
  if (statusCode) return 'page-not-found';
  if (isNewCategoryTree) return 'newCategoryTree';
  return 'category';
};
