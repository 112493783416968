















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { MAX_POINTS_FOR_RATINGS } from '~/constants/reviews';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';

export default defineComponent({
  name: 'ReviewsStars',
  components: {
    ReviewRatingSchema: () => import(/* webpackChunkName: "ReviewRatingSchema" */
      '~/components/molecules/Review/ReviewRatingSchema/ReviewRatingSchema.vue'),
  },
  props: {
    rating: {
      type: [Number, String],
      default: 0,
    },
    showSchema: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const ratingNumber = computed(() => Number(props.rating));

    const getStar = (item) => {
      if (ratingNumber.value >= item) {
        return 'iconify svg--star';
      } else if (ratingNumber.value < item && ratingNumber.value > item - 1) {
        return 'iconify svg--star-half';
      } else if (ratingNumber.value < item && ratingNumber.value < item - 0.5) {
        return 'iconify svg--star-empty';
      }
    };
    return {
      MAX_POINTS_FOR_RATINGS,
      getStar,
      SCHEMA_ITEM_PROP,
      SCHEMA_ITEM_TYPE,
      ratingNumber,
    };
  },
});
