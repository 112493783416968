

















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { productTag } from '~/types/product/attribute/ProductTag';

export default defineComponent({
  name: 'ProductCardBadgeWrapper',
  components: {
    ProductCardBadges: () => import(/* webpackChunkName: "ProductCardBadges" */
      '~/components/molecules/Product/ProductCardBadges.vue'),
    ProductCardDisclaimer: () => import(/* webpackChunkName: "ProductCardBadges" */
      '~/components/molecules/Product/ProductCardDisclaimer.vue'),
  },
  props: {
    attributesLocalized: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const tags = computed(() => {
      return props.attributesLocalized?.tags ?? [];
    });

    const isBWareProduct = tags.value.includes(productTag.Bware);

    return {
      tags,
      isBWareProduct,
    };
  },
});
