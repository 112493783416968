import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { defaultVideoLanguageAndCountry } from '~/constants/language';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { ProductVideo } from '~/types/product/ProductVideo';

export const getProductVideo = (product: ProductVariant, languageAndCountry: string): ProductVideo | null => {
  const videoId = getProductAttributesExtended(
    product,
    {
      attributeNames: [PRODUCT_ATTRIBUTES.VIDEO],
      languageAndCountry,
    })[PRODUCT_ATTRIBUTES.VIDEO] ||
    getProductAttributesExtended(
      product,
      {
        attributeNames: [PRODUCT_ATTRIBUTES.VIDEO],
        languageAndCountry: defaultVideoLanguageAndCountry,
      })[PRODUCT_ATTRIBUTES.VIDEO];

  if (!videoId) {
    return null;
  }

  const link = `https://www.youtube.com/embed/${videoId}`;
  const thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  return {
    link,
    thumbnail,
  };
};
