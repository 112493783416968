
































import { defineComponent } from '@vue/composition-api';
import { useIsPage } from '~/composables';
export default defineComponent({
  name: 'SearchResultsTopSkeleton',
  setup() {
    const { isOnInternalCmsPage } = useIsPage();

    return {
      isOnInternalCmsPage,
    };
  },
});
