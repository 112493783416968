




























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { SIMPLE_CAROUSEL_BREAKPOINTS } from '~/constants/simpleCarousel';
import BlogCard from '~/components/molecules/Blog/BlogCard.vue';
import type { BlogCardItem } from '~/types/blog/BlogCardItem';
export default defineComponent({
  name: 'BlogSlider',
  components: {
    SimpleCarousel,
    BlogCard,
  },
  props: {
    posts: {
      type: Array as PropType<BlogCardItem[]>,
      default: () => [],
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showPaginationControls: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const blogSliderBreakpointsConfig = {
      [SIMPLE_CAROUSEL_BREAKPOINTS.SM]: {
        perPage: 1.7,
      },
      [SIMPLE_CAROUSEL_BREAKPOINTS.MD]: {
        perPage: 3,
      },
      [SIMPLE_CAROUSEL_BREAKPOINTS.LG]: {
        perPage: 4,
      },
    };

    return {
      SIMPLE_CAROUSEL_BREAKPOINTS,
      blogSliderBreakpointsConfig,
    };
  },
});
