var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-pagination',{attrs:{"padding":2},on:{"page-change":_vm.scrollUpToSearchResults},scopedSlots:_vm._u([{key:"first",fn:function(ref){
var createURL = ref.createURL;
var isFirstPage = ref.isFirstPage;
var refine = ref.refine;
return [(!isFirstPage)?_c('a',{staticClass:"ais-Pagination-link",attrs:{"aria-label":_vm.$t('First'),"href":createURL(0)},on:{"click":function($event){$event.preventDefault();return refine(0)}}},[_vm._v(_vm._s(_vm.$t('First')))]):_c('span',{staticClass:"ais-Pagination-link",attrs:{"aria-label":_vm.$t('First')}},[_vm._v(_vm._s(_vm.$t('First')))])]}},{key:"last",fn:function(ref){
var isLastPage = ref.isLastPage;
var createURL = ref.createURL;
var refine = ref.refine;
return [(!isLastPage)?_c('a',{staticClass:"ais-Pagination-link",attrs:{"aria-label":"Last","href":createURL()},on:{"click":function($event){$event.preventDefault();return refine()}}},[_vm._v(_vm._s(_vm.$t('Last')))]):_c('span',{staticClass:"ais-Pagination-link",attrs:{"aria-label":"Last"}},[_vm._v(_vm._s(_vm.$t('Last')))])]}},{key:"previous",fn:function(ref){
var createURL = ref.createURL;
var isFirstPage = ref.isFirstPage;
var refine = ref.refine;
return [(!isFirstPage)?_c('a',{staticClass:"ais-Pagination-link iconify svg--chevron-left",attrs:{"aria-label":_vm.$t('Previous'),"href":createURL()},on:{"click":function($event){$event.preventDefault();return refine()}}}):_c('span',{staticClass:"ais-Pagination-link iconify svg--chevron-left",attrs:{"aria-label":_vm.$t('Previous')}})]}},{key:"next",fn:function(ref){
var createURL = ref.createURL;
var isLastPage = ref.isLastPage;
var refine = ref.refine;
return [(!isLastPage)?_c('a',{staticClass:"ais-Pagination-link iconify svg--chevron-right",attrs:{"aria-label":_vm.$t('Next'),"href":createURL(),"data-testid":"paginationNextPage"},on:{"click":function($event){$event.preventDefault();return refine()}}}):_c('span',{staticClass:"ais-Pagination-link iconify svg--chevron-right",attrs:{"aria-label":_vm.$t('Next')}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }