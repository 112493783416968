var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:[
    'ais-HierarchicalMenu-list',
    _vm.level > 0 && 'ais-HierarchicalMenu-list--child',
    ("ais-HierarchicalMenu-list--lvl" + _vm.level)
  ]},_vm._l((_vm.items),function(item){return _c('li',{key:item.value,class:[
      'ais-HierarchicalMenu-item',
      item.data && 'ais-HierarchicalMenu-item--parent',
      item.isRefined && 'ais-HierarchicalMenu-item--selected'
    ]},[_c('a',{staticClass:"ais-HierarchicalMenu-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleClick(item)}}},[_c('span',{staticClass:"ais-HierarchicalMenu-label"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('span',{staticClass:"ais-HierarchicalMenu-count"},[_vm._v(_vm._s(item.count))])]),_vm._v(" "),(item.data && item.data.length > 0)?_c('CustomHierarchicalMenuList',{attrs:{"items":item.data,"level":_vm.level + 1,"refine":_vm.refine}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }