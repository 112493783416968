


















import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';

// Import like this is needed for SSR to work
// https://nightcatsama.github.io/vue-slider-component/#/?hash=server-side-rendering-ssr
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js';
import type { ValueArray, ValueRange, ValueRangeOptional } from '~/types/ValueRange';

import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';

interface GetValue {
  (): [number, number];
}
interface Slider extends HTMLElement {
  getValue: GetValue
}

export default defineComponent({
  name: 'RangeSlider',
  components: { VueSlider },
  props: {
    currentRange: {
      type: Object as PropType<ValueRangeOptional>,
      required: true,
    },
    maxRange: {
      type: Object as PropType<ValueRange>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const sliderRef = ref<Slider | null>(null);

    const rangeArray = computed(() => {
      const min = props.currentRange.min ?? props.maxRange.min;
      const max = props.currentRange.max ?? props.maxRange.max;

      return [min, max];
    });

    const customMaxRange = computed(() => {
      const min = props.maxRange.min;
      let max = props.maxRange.max;

      if (min === max) {
        max = max + 1; // needed to prevent error when clicking on slider (even when it is disabled)
      }

      return { min, max };
    });

    function arrayToRange(array: ValueArray): ValueRange {
      return { min: array[0], max: array[1] };
    }

    function onInput(values: ValueArray): void {
      emit('input', arrayToRange(values));
    }

    function onEnd(): void {
      const values = sliderRef.value?.getValue();
      const range = values ? arrayToRange(values) : props.maxRange;
      emit('end', range);
    }

    return { rangeArray, onInput, onEnd, customMaxRange, sliderRef };
  },
});
