














import { defineComponent } from '@nuxtjs/composition-api';
import { AisRatingMenu } from 'vue-instantsearch';
import RatingMenuInner from '~/components/molecules/Search/RatingMenuInner.vue';
import { ALGOLIA_ATTRIBUTE, MAX_STARS } from '~/constants/algolia';

export default defineComponent({
  name: 'RatingMenu',
  components: {
    AisRatingMenu,
    RatingMenuInner,
  },
  setup() {
    return { ALGOLIA_ATTRIBUTE, MAX_STARS };
  },
});
