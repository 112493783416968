

























import { defineComponent, computed } from '@vue/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import BrandImage from '~/components/molecules/Product/Brand/BrandImage/BrandImage.vue';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import { getBrandInfo } from '~/helpers/brand';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export default defineComponent({
  name: 'ProductQuickDetails',
  components: {
    LazyHydrate,
    BrandImage,
    TrustAccordion: () => import(/* webpackChunkName: "TrustAccordion" */
      '~/components/organisms/Product/TrustAccordion/TrustAccordion.vue'),
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue'),
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true,
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true,
    },
    shouldShowRelatedAccessories: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const brandInfo = computed(() => getBrandInfo(props.attributesLocalized?.brand) || {});
    const hasEnergyRating = computed(() => props.attributesLocalized?.energyRating?.rating);
    const manual = computed(() =>
      getProductAttributesExtended(
        props.product,
        {
          attributeNames: [PRODUCT_ATTRIBUTES.MANUAL_URL],
        })[PRODUCT_ATTRIBUTES.MANUAL_URL] || '',
    );

    return {
      brandInfo,
      hasEnergyRating,
      manual,
    };
  },
});
