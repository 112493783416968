




























import { defineComponent } from '@nuxtjs/composition-api';
import { useI18n, useTrustPilotWidget } from '~/composables';
import { TRUSTPILOT_CAROUSEL_WIDGET_HEIGHT, TRUSTPILOT_MINI_CAROUSEL_WIDGET_HEIGHT } from '~/constants/ui';
import { trustpilotWidgetCaruselId, trustpilotWidgetMiniCaruselId } from '~/config';
import { TRUSTPILOT_CAROUSEL_STARS_RATING } from '~/constants/trustPilot';

export default defineComponent({
  name: 'TrustPilotCarousel',
  setup() {
    const { languageAndCountry, language } = useI18n();
    const { showTrustPilotWidget } = useTrustPilotWidget();

    return {
      languageAndCountry,
      TRUSTPILOT_CAROUSEL_WIDGET_HEIGHT,
      TRUSTPILOT_MINI_CAROUSEL_WIDGET_HEIGHT,
      TRUSTPILOT_CAROUSEL_STARS_RATING,
      trustpilotWidgetCaruselId,
      trustpilotWidgetMiniCaruselId,
      showTrustPilotWidget,
      language,
    };
  },
});
