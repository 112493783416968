












import { defineComponent } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import { SCHEMA_ITEM_PROP } from '~/constants/seo';

export default defineComponent({
  name: 'ProductTitle',
  components: {
    SfHeading,
  },
  props: {
    productTitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      SCHEMA_ITEM_PROP,
    };
  },
});
