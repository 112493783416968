<template>
  <ul
    :class="[
      'ais-HierarchicalMenu-list',
      level > 0 && 'ais-HierarchicalMenu-list--child',
      `ais-HierarchicalMenu-list--lvl${level}`
    ]"
  >
    <li
      v-for="item in items"
      :key="item.value"
      :class="[
        'ais-HierarchicalMenu-item',
        item.data && 'ais-HierarchicalMenu-item--parent',
        item.isRefined && 'ais-HierarchicalMenu-item--selected'
      ]"
    >
      <a
        href="#"
        class="ais-HierarchicalMenu-link"
        @click.prevent="handleClick(item)"
      >
        <span class="ais-HierarchicalMenu-label">{{ item.label }}</span>
        <span class="ais-HierarchicalMenu-count">{{ item.count }}</span>
      </a>
      <CustomHierarchicalMenuList
        v-if="item.data && item.data.length > 0"
        :items="item.data"
        :level="level + 1"
        :refine="refine"
      />
    </li>
  </ul>
</template>

<script>
import { useClickFiltersEvent } from '~/composables';
import { EVENT_SOURCE, FILTERS_INTERACTIONS } from '~/constants/googleTagManager';

export default {
  name: 'CustomHierarchicalMenuList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
    refine: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { callClickFilterEvent } = useClickFiltersEvent();

    const handleClick = (item) => {
      const filterInteraction =
        item.isRefined ? FILTERS_INTERACTIONS.FILTER_UNSELECT : FILTERS_INTERACTIONS.FILTER_SELECT;

      callClickFilterEvent({
        filterInteraction,
        filterSection: EVENT_SOURCE.HIERARCHICAL_TREE,
        filterName: item.label,
        filterValue: item.value,
      });

      props.refine(item.value);
    };

    return {
      handleClick,
    };
  },
};
</script>
