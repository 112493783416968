





















































import { defineComponent, ref } from '@nuxtjs/composition-api';
import {
  AisStats,
  AisSearchBox,
} from 'vue-instantsearch';
import { MIN_CHARS_FOR_SEARCH, MAX_LENGTH_FOR_SEARCH_INPUT } from '~/constants/algolia';
import CustomCurrentRefinements from '@/components/molecules/Search/CustomCurrentRefinements.vue';
import ClearRefinements from '@/components/atoms/Search/ClearRefinements.vue';
import { useAlgolia, useRouteExtended, useIsPage } from '~/composables';
import { ROUTES } from '~/constants/routes';

export default defineComponent({
  name: 'SearchFiltersHeader',
  components: {
    AisStats,
    ClearRefinements,
    AisSearchBox,
    CustomCurrentRefinements,
  },
  setup(_props, { root }) {
    const { isFiltering } = useAlgolia();
    const { getAdjustedSlug } = useRouteExtended();
    const { isOnSearchPage, isOnInternalCmsPage } = useIsPage();
    const goToSearchPage = () => {
      root.$router.push(getAdjustedSlug(ROUTES.SEARCH));
    };
    const currentValue = ref<{ name: string; url: string; }>({ name: '', url: '' });

    const updateSearch = (value: string, refine: (string) => void) => {
      if (!value) {
        currentValue.value.name = '';
      }
      if (value.length < MIN_CHARS_FOR_SEARCH) {
        return;
      }
      refine(value);
      currentValue.value = { name: value, url: '' };
    };

    return {
      isFiltering,
      isOnSearchPage,
      isOnInternalCmsPage,
      goToSearchPage,
      updateSearch,
      currentValue,
      MAX_LENGTH_FOR_SEARCH_INPUT,
    };
  },
});
