













import { defineComponent } from '@nuxtjs/composition-api';
import CustomRefinementList from '@/components/molecules/Search/CustomRefinementList.vue';
import { TOP_STATIC_FACETS } from '~/constants/algolia';
import extractDynamicFacets from '~/helpers/algolia/extractDynamicFacets';

export default defineComponent({
  name: 'SearchFiltersBody',
  components: {
    CustomRefinementList,
  },
  setup () {
    return {
      TOP_STATIC_FACETS,
      extractDynamicFacets,
    };
  },
});
