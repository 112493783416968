









































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { PropType } from '@nuxtjs/composition-api';
import type { RefinementListItem } from 'vue-instantsearch';
import { useClickFiltersEvent } from '~/composables';
import CheckBox from '~/components/atoms/Buttons/CheckBox/CheckBox.vue';
import FacetCount from '~/components/atoms/Search/FacetCount/FacetCount.vue';
import ReviewsStars from '~/components/atoms/ReviewsStars.vue';
import { RATING_TO_IGNORE } from '~/constants/algolia';
import { MAX_POINTS_FOR_RATINGS, MIN_POINTS_FOR_RATINGS } from '~/constants/reviews';
import { EVENT_SOURCE, FILTERS_INTERACTIONS } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'RatingMenuInner',
  components: {
    CheckBox,
    FacetCount,
    ReviewsStars,
  },
  props: {
    items: {
      type: Array as PropType<RefinementListItem[]>,
      required: true,
    },
    refine: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const { i18n } = useVSFContext();
    const { callClickFilterEvent } = useClickFiltersEvent();
    const localisedName = computed(() => i18n.t('Ratings'));

    const handleChange = (item: RefinementListItem) => {
      const filterInteraction =
        item.isRefined ? FILTERS_INTERACTIONS.FILTER_UNSELECT : FILTERS_INTERACTIONS.FILTER_SELECT;

      callClickFilterEvent({
        filterInteraction,
        filterSection: EVENT_SOURCE.RATINGS,
        filterName: localisedName.value,
        filterValue: item.value,
      });
      props.refine(item.value);
    };

    const filteredItems = computed(() => props.items.filter(
      item => item.value !== RATING_TO_IGNORE && item.count !== 0,
    ));

    const minRating = MIN_POINTS_FOR_RATINGS.toString();
    const maxRating = MAX_POINTS_FOR_RATINGS.toString();

    return {
      filteredItems,
      minRating,
      maxRating,
      handleChange,
    };
  },
});
