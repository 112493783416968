










import { defineComponent, computed } from '@nuxtjs/composition-api';
import SearchFiltersHeader from '~/components/organisms/Search/SearchFiltersHeader.vue';
import SearchFiltersBody from '~/components/organisms/Search/SearchFiltersBody.vue';
import SideCategories from '~/components/molecules/Category/SideCategories/SideCategories.vue';
import { useCategories, useIsPage } from '~/composables';

export default defineComponent({
  name: 'SearchFilters',
  components: {
    SideCategories,
    SearchFiltersBody,
    SearchFiltersHeader,
  },
  setup() {
    const { isOnSearchPage } = useIsPage();
    const { isNewCategoryTree } = useCategories();

    const showSideCategories = computed(() => !isOnSearchPage.value && !isNewCategoryTree.value);

    return {
      showSideCategories,
    };
  },
});
