


















import { defineComponent, ref } from '@vue/composition-api';
import SalesforceWebform from '~/components/organisms/SalesforceWebform/SalesforceWebform.vue';

export default defineComponent({
  name: 'RequestCustomQuote',
  components: {
    SalesforceWebform,
  },
  props: {
    sku: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isContactFormVisible = ref(false);
    const toggleContactFormVisibility = () => {
      isContactFormVisible.value = !isContactFormVisible.value;
    };

    return {
      toggleContactFormVisibility,
      isContactFormVisible,
    };
  },
});
