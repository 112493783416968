












































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { onClickOutside } from '@vueuse/core';
import { useIntegrations } from '~/composables';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';

export default defineComponent({
  name: 'AlternativeProductDropdown',
  components: {
    Link,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    isAware: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { i18n } = useVSFContext();
    const { $tagManager } = useIntegrations();

    const dropdownTrigger = ref(null);
    const isOpen = ref(false);

    const aWareTitle = i18n.t('New');
    const bWareTitle = i18n.t('Refurbished');

    const currentTitle = computed(() => props.isAware ? aWareTitle : bWareTitle);
    const dropdownTitle = computed(() => props.isAware ? bWareTitle : aWareTitle);
    const dropdownDescription = computed(() => props.isAware ? i18n.t('b-ware disclaimer') : '');

    onClickOutside(dropdownTrigger, () => {
      isOpen.value = false;
    });

    const onToggle = () => {
      isOpen.value = !isOpen.value;
    };

    const onClick = () => {
      $tagManager.events.triggerClickAlternativeProduct(!props.isAware, props.sku);
    };

    return {
      dropdownTrigger,
      currentTitle,
      dropdownTitle,
      dropdownDescription,
      isOpen,
      LinkType,
      onToggle,
      onClick,
    };
  },
});

