






import { defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

export default defineComponent({
  name: 'YearsOfExperience',
  setup() {
    const { i18n } = useVSFContext();
    const yearsOfExistence = new Date().getFullYear() - 2008;
    const yearsOfExperience = i18n.t('number years of experience', { yearsOfExistence });

    return {
      yearsOfExperience,
    };
  },
});
