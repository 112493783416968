<!-- This component must only be used for displaying HTML written internally in the company.
Third-party content might be injected with harmful scripts (even as inline js or css) intentionally or not.
DO NOT USE UNLESS YOU ARE SURE THE CONTENT IS SAFE -->
<template>
  <div
    v-html="cleanHtml"
  />
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: 'DisplayHtmlDangerously',
  props: {
    htmlText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {
      cleanHtml: computed(() => sanitizeHtml(props.htmlText, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ['src', 'alt', 'title', 'width', 'height'],
        },
      })),
    };
  },
});
</script>
