



















































































































import { defineComponent } from '@nuxtjs/composition-api';
import { useI18n, useTrustPilotWidget } from '~/composables';
import {
  TRUSTPILOT_MINI_WIDGET_WIDTH,
  TRUSTPILOT_MINI_WIDGET_HEIGHT,
} from '~/constants/ui';
import { trustpilotMiniWidgetId } from '~/config';
import YearsOfExperience from '~/components/atoms/YearsOfExperience/YearsOfExperience.vue';

export default defineComponent({
  name: 'TrustedBy',
  components: {
    YearsOfExperience,
  },
  setup() {
    const { languageAndCountry } = useI18n();
    const { showTrustPilotWidget } = useTrustPilotWidget();

    return {
      languageAndCountry,
      showTrustPilotWidget,
      trustpilotMiniWidgetId,
      TRUSTPILOT_MINI_WIDGET_WIDTH,
      TRUSTPILOT_MINI_WIDGET_HEIGHT,
    };
  },
});
