











import { defineComponent } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { useInternalCms } from '~/composables';
import { COMPONENTS } from '~/constants/cms';
import RenderContent from '~/cms/RenderContent.vue';

export default defineComponent({
  name: 'MissingTranslationNotification',
  components: {
    RenderContent,
  },
  setup () {
    const {
      loadComponent,
      canShowContent,
      internalCmsContent,
    } = useInternalCms(COMPONENTS.NOTIFICATION_BANNER.SLUG);

    onSSR(async () => {
      await loadComponent();
    });

    return {
      canShowContent,
      internalCmsContent,
    };
  },
});
