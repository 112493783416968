































import {
  defineComponent,
  computed,
  watch,
  ref,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import DisplayHtmlDangerously from '~/components/atoms/DisplayHtmlDangerously/DisplayHtmlDangerously.vue';
import { dateToDDMMYYYY } from '~/helpers/time/dateToDDMMYYYY/dateToDDMMYYYY';
import type { BlogCardItem } from '~/types/blog/BlogCardItem';
import ExternalLink from '~/components/atoms/Link/ExternalLink/ExternalLink.vue';

export default defineComponent({
  name: 'BlogCard',
  components: {
    ImgView,
    DisplayHtmlDangerously,
    ExternalLink,
  },
  props: {
    post: {
      type: Object as PropType<BlogCardItem>,
      required: true,
    },
  },
  setup (props) {
    const creationDate = computed(() => dateToDDMMYYYY(props.post.createdAt));
    const blogPostKey = ref('');
    watch(() => props.post, (value) => {
      blogPostKey.value = value.title;
    });
    return {
      dateToDDMMYYYY,
      creationDate,
      blogPostKey,
    };
  },
});
