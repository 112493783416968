import { AgnosticAttribute } from '@vue-storefront/core/lib/src/types';
import { ProductRelatedAccessories } from '~/types/product/attribute/ProductRelatedAccessories';
import { excludeUnsellableProduct } from '~/helpers/commercetools/graphql/excludeUnsellableProduct';

const createCustomFilterForAccessories = (accessoriesList: AgnosticAttribute, countryCode: string): string => {
  const accessoriesValue: ProductRelatedAccessories[] = Object.values(accessoriesList);
  const customFilterForUnsellableProdcuct = excludeUnsellableProduct(countryCode);
  let customFilter = '';
  accessoriesValue.forEach((accessory, index) => {
    if (index === 0) {
      customFilter = ` and id=\"${accessory.id}\"`;
    } else {
      customFilter += ` or id=\"${accessory.id}\" and ${customFilterForUnsellableProdcuct}`;
    }
  });

  return customFilter;
};

export default (accessoriesList: AgnosticAttribute, countryCode: string) => {
  return {
    customFilters: createCustomFilterForAccessories(accessoriesList, countryCode),
  };
};
