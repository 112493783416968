

























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfCheckbox } from '@storefront-ui/vue';
export default defineComponent({
  name: 'CheckBox',
  components: {
    SfCheckbox,
  },
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
    isRectangular: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const checkedIconSrc = '/searchpage/filter-checked.svg';
    const uncheckedIconSrc = '/searchpage/filter-unchecked.svg';

    const currentIconSrc = computed(() => props.isChecked ? checkedIconSrc : uncheckedIconSrc);
    return {
      currentIconSrc,
    };
  },
});
