













































import { defineComponent } from '@nuxtjs/composition-api';
import { AisCurrentRefinements, AisHierarchicalMenu } from 'vue-instantsearch';
import { useIsPage } from '~/composables';
import { CATEGORIES_ATTRIBUTES } from '~/constants/algolia';
import { getCustomCategoryTreeOrder } from '~/helpers/algolia/getCustomCategoryTreeOrder';
import CurrentRefinementLabel from '~/components/molecules/Search/CurrentRefinementLabel/CurrentRefinementLabel.vue';
import CustomHierarchicalMenuList from '@/components/molecules/Search/CustomHierarchicalMenuList.vue';

export default defineComponent({
  name: 'CustomCurrentRefinements',
  components: {
    AisCurrentRefinements,
    AisHierarchicalMenu,
    CurrentRefinementLabel,
    CustomHierarchicalMenuList,
  },
  setup () {
    const { isOnSearchPage, isOnInternalCmsPage } = useIsPage();

    return {
      CATEGORIES_ATTRIBUTES,
      isOnSearchPage,
      isOnInternalCmsPage,
      getCustomCategoryTreeOrder,
    };
  },
});
